@tailwind base;
@tailwind components;
@tailwind utilities;

.otp_input input{
    background: #F9F9F9 !important;
    border: 1px solid #CCCCCC !important;
    border-radius: 5px !important;
    box-shadow: none !important;
    outline: 0px !important;
    font-weight: 300 !important;
    font-size: 14px !important;
    letter-spacing: 0.015em;
    color: rgba(152, 152, 152, 1) !important;
    height: 48px;
    width: 20% !important;
    margin: 0px 20px;
}

ol, ul{
    padding-left: 40px;
}

li{
    list-style: disc;
}

h1{
    font-size: 2.5rem;
    margin-bottom: .5rem;
}

h2{
    font-size: 2rem;
    margin-bottom: .5rem;
}

h3{
    font-size: 1.75rem;
    margin-bottom: .5rem;
}

h4{
    font-size: 1.5rem;
    margin-bottom: .5rem;
}

h5{
    font-size: 1.25rem;
    margin-bottom: .5rem;
}

h6{
    font-size: 1rem;
    margin-bottom: .5rem;
}